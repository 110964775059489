.addCandidateDrawer {
  display: block;

  .uploadResume {
    background: white;
    span {
      padding: 8px 0 !important;
    }
    .uploadText {
      color: #1f2730;
    }
  }

  .uploadHintText {
    font-size: 12px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .formItem {
    margin: 24px;
    padding-right: 220px;
  }

  .footer {
    display: flex;
    border-top: 1px solid #dadbdd;
    justify-content: flex-end;
    gap: 28px;
    padding-top: 16px;
    padding-right: 32px;
    padding-bottom: 16px;
  }

  .form {
    height: 100%;
  }

  .countryDropdown {
    #prefix {
      width: 150px;
    }
  }
  .otherSource {
    margin-top: 5px;
  }
  .contextButton {
    border-radius: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .contextButton::before {
    background-color: transparent !important;
  }
}
