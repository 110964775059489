.candidateListContainer {
  padding-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 24px;
    }
    .internalIcon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
