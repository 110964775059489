.candidateDetail {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;

  h2 {
    font-size: 14px;
    font-weight: 400;
    color: #0a090b;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: #7f7d83;
  }
}
.contextButton {
  border-radius: 20px !important;
  margin-bottom: 10px;
  margin-right: 10px;
}
.contextButton::before {
  background-color: transparent !important;
}

.formItem {
  margin-top: 24px;
}

.contextSearch {
  display: block;
}

.candidateTransferIcon {
  height: 50px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 20px;
}
