#navbar-wrapper {
  background-color: #1f2730;
  height: var(--navbar-height);
  width: 100%;
  position: fixed;
  z-index: 2000;
  top: 0;

  .impersonate-banner {
    // width: var(--app-width);
    // max-width: var(--app-max-width);
    background-color: #de7e00;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 55px;
    position: relative;

    .stop-impersonation {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .december-banner {
    background-color: #00de55c4;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 55px;
    position: relative;
    font-size: medium;
  }
}

.navbar {
  width: var(--app-width);
  max-width: var(--app-max-width);
  margin: auto;
  height: 100%;
  color: #a8b7c7;
  display: flex;
  align-items: center;
  padding: 0px 55px;
  position: relative;

  .system-notifcation-popup-title {
    padding: 10px 0px;
    margin: 0;
  }
}

.navbar .anticon {
  padding: 12px;
}

.navbar a {
  color: #a8b7c7;
  display: block;
  height: 100%;
}

.navbar-jobs-tab {
  margin-left: auto;
}

.navbar-more-tab-item {
  font-size: 16px;
  padding: 4px;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-menu-tab-item {
  font-size: 16px;
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  // &.last {
  //   padding-right: 24px;
  // }
}

.navbar-menu-tab-item.active {
  background-color: #353d45;
  border-bottom: 4px solid #13c26b;
  color: #ffffff;
  padding: 15px 15px 11px 15px;
}

.navbar-menu-item-icon {
  padding: 15px 15px 15px 15px;
  cursor: pointer;

  &.last {
    padding: 15px 0px 15px 6px;
  }

  .arrow-icon {
    padding: 12px 0px;
    margin-left: 6px;
  }
}

.logo img {
  height: 35px;
  cursor: pointer;
}

.navbar-notification-icon {
  font-size: 20px;
}

.avatar-icon {
  margin-right: 6px;
}

.system-notifications,
.connect-notifications {
  max-height: 300px;
  overflow-y: scroll;
}

.go-to-connect {
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar .connect-notification-icon {
  padding: 0px;
}

.navbar .arya-notification-icon {
  padding: 0px;
}

.navbar .badged-icon {
  // padding: 18px;
  // margin-top: 5px;
  font-size: 22px;

  .connect {
    .ant-badge-count {
      background: #9254de;
      box-shadow: none;
      height: 14px;
      width: 14px;
      line-height: 14px;
      padding: 1px 3px;
      font-size: 10px;
      border-radius: 4px;
    }
  }

  .arya {
    .ant-badge-count {
      background-color: #1890ff;
      box-shadow: none;
      height: 14px;
      width: 14px;
      line-height: 14px;
      padding: 1px 3px;
      font-size: 10px;
      border-radius: 4px;
    }
  }

  .arya,
  .connect {
    p {
      color: white;
    }
  }
}

.connect-notification-icon.wiggle,
.arya-notification-icon.wiggle {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.arrow-icon {
  position: absolute;
}

.more-dropdown {
  ul {
    position: relative;
    background: #1f2730;
    width: 252px;
    border-radius: 3px;
    padding: 8px 0;
  }

  .more-arrow-icon {
    top: -3px;
    right: 24px;
    background: #1f2730;
  }

  .more-dropdown-item {
    padding: 0 9px 0px 9px;
    background: transparent !important;
    margin-bottom: 0 !important;
    margin-top: 0;
    height: 30px;

    .more-dropdown-tab-item {
      font-size: 16px;
      padding: 4px;
      line-height: 21px;
    }
  }

  .more-dropdown-item > a {
    color: white;
  }
  .more-dropdown-item:hover {
    background-color: transparent;
  }
}

.candidate-menu-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .add-candidate-btn {
    border: none;
    color: #36cf7d;
    padding: 0 0 7px 0;
    span {
      text-decoration: underline;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.system-notification-empty,
.connect-notification-empty {
  height: 197px;
  width: 361px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.system-notification-empty-icon-wrapper,
.connect-notification-empty-icon-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.system-notification-empty-title,
.connect-notification-empty-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: center;
}

.system-notification-empty-description,
.connect-notification-empty-description {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
}

.vanish {
  display: none;
}
